import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/utils/layout";
import { RebassCard } from "../utils/styled";
import { Tiles, Flex } from 'rebass';
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import { Link } from "gatsby";
import SEO from "../components/utils/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Termes & conditions" mdxType="SEO" />
    <h1>{`Termes & conditions:`}</h1>
    <br />
    <h3>{`D'abord, parlons un peu de moi:`}</h3>
    <p>{`Je m'appele Alexandre Ramalho et je travaile, actuellement, comme ingénieure full-stack software a `}<a parentName="p" {...{
        "href": "https://vwds.pt"
      }}>{`Volkswagen Digital Solutions`}</a>{`.`}</p>
    <p>{`J'aime beaucoup apprendre. Vraiment beaucoup. Alors j'essaye toujours de trouver des nouveaux ''pet project'' pour me mettre au défi.
Actuallement, je suis en train de faire une `}<a parentName="p" {...{
        "href": "https://radialcor.pt"
      }}>{`platforme E-commerce pour Radialcor`}</a>{` en utilisant Django et
Wagtail, parce que je voulais devenir plus confortable avec Python, et Django est un Web framework hautement fiable. J'ai aussi commencer
a apprendre plus sur Jamstack et j'ai decidé de lui donner une chance, alors j'ai fais ce website en utilisant
`}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{`. Ceci est juste un petit fond d'histoire pour vous aider a me connaître mieux. Vous pouvez aussi jeter un coup d'oeil sur ma page
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/alexandre-francisco-ramalho/"
      }}>{`LinkedIn`}</a>{` pour connaître encore plus mon histoire et mon educacion et experience professionnel.`}</p>
    <p>{`Maintenant, en vrai dire, `}<strong parentName="p">{`que puis-je faire pour vous?`}</strong></p>
    <p>{`Tout et n'importe quoi, littéralement. Je ne vois pas ça comme si vous m'acheter un service (même si ça l'est),
je vois ça comme si vous vouliez quelqu'un pour developer vos projets et moi des projets pour developer. Je suis également prêt à faire
un partenariat avec d'autres développeurs si un projet est suffisamment attirant. J'en apprends actuellement plus sur Jamstack / Machine Learning et je prévois aussi de  plonger dans la programmation fonctionnelle après.
Subséquement, les projets qui me permettent d'utiliser ces technologies seraient de mon intérêt particulier :)
La façon dont cela marchera est la suivant:`}</p>
    <Flex flexWrap='wrap' mdxType="Flex">
    <RebassCard variant icon={1} mdxType="RebassCard">
    Vous entrez en contact avec moi, soit par
    <Link to="/#go" mdxType="Link"><i> contact form </i></Link>
   ou à travers
    <a target="_blank" href="https://www.linkedin.com/in/alexandre-francisco-ramalho/"> <i>LinkedIn</i></a> avec un projet. <i>suggestion</i>.
    </RebassCard>
    <RebassCard variant icon={2} mdxType="RebassCard">
    J'évalue si et comment c'est faisable.
    </RebassCard>
    <RebassCard variant icon={3} mdxType="RebassCard">
   Je vous recontacte soit pour vous dire que je ne suis pas intéressé ou alors que je suis prêt à continuer!
    </RebassCard>
    <RebassCard variant icon={4} mdxType="RebassCard">
    Ensuite nous marquons une réunion afin de discuter les prix, les exigences et pour définir une chronologie approximative pour le projet.
    </RebassCard>
    <RebassCard variant icon={5} mdxType="RebassCard">
   Quand j'ai un prototype prêt, vous l'évaluez et soit:
        <br />👎 Vous me dites non;
        <br />👍 Vous me dites <b>allons-y</b> et vous payez 50% du prix discuté précédemment.
    </RebassCard>
    <RebassCard variant icon={6} mdxType="RebassCard">
    Je continue de travailler sur le projet.
    </RebassCard>
    <RebassCard variant icon={7} mdxType="RebassCard">
    Je rends le projet final et vous payez les 50% restants.
    </RebassCard>
    <RebassCard variant icon={8} mdxType="RebassCard">
    ð Vous êtes content!
    </RebassCard>
    <RebassCard variant icon={9} mdxType="RebassCard">
    Je suis content! ð
    </RebassCard>
    </Flex>
    <p>{`J'espère vous avoir convaincu a me donner une chance! :)
Si vous avez le moindre doute, n'hésitez pas à me contacter via l'un de mes réseaux sociaux ou remplissez la boîte de message qui se trouve sur la page principale!`}</p>
    <Link to="/#go" mdxType="Link">Go back to the homepage</Link>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      